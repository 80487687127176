<template>
  <power-bi code="eyJrIjoiMmY2ZWE4MzctMTg5Yi00OGQ0LTg5MmItYjQ3M2FhNzE0ODJkIiwidCI6ImM4NDJjMWQ5LWQzNjQtNDcxNi1iN2UzLWNhNDgxYTIzZDZhYyJ9" />
</template>

<script>
import PowerBi from '../components/PowerBi.vue';

export default {
    components: {
        PowerBi,
    },
};
</script>
